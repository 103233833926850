[data-page-element="Navigation/V1"] {
      font-size: 20px;
    }
      [data-page-element="Navigation/V1"] {
      --nav-icon-placement-setting: -1;
    }
[data-page-element="Navigation/V1"] div,
[data-page-element="Navigation/V1"] a,
[data-page-element="Navigation/V1"] i {
      margin: 0px;
    }
[data-page-element="Navigation/V1"] a.elTypographyLink:not(.elNavigatorButton) {
      display: flex;
align-items: center;
    }
[data-page-element="Navigation/V1"] .elTypographyLink {
      color: #2D2D2D;
    }
[data-page-element="Navigation/V1"] a.elTypographyLink > i {
      order: var(--nav-icon-placement-setting);
margin: 0 4px;
    }
[data-page-element="Navigation/V1"] .fa_apended.fa_apended {
      order: 0;
    }
[data-page-element="Navigation/V1"] .elModalInnerContainer {
      width: 100%;
    }
[data-page-element="Navigation/V1"] .elNavigatorButton {
      color: #2D2D2D;
display: none;
flex-direction: row;
justify-content: start;
cursor: pointer;
    }
[data-page-element="Navigation/V1"] .elNavigatorButton:hover {
      text-decoration: none;
    }
[data-page-element="Navigation/V1"] .elNavigatorDesktop {
      gap: 1.5em;
display: flex;
    }
[data-page-element="Navigation/V1"] .elNavigatorDesktop[data-direction="row"] {
      flex-direction: row;
align-items: center;
justify-content: var(--layout-justification-setting);
    }
[data-page-element="Navigation/V1"] .elNavigatorDesktop[data-direction="column"] {
      flex-direction: column;
align-items: var(--layout-justification-setting);
justify-content: center;
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile {
      padding: 20px;
padding-top: 60px;
z-index: 100000;
display: flex;
flex-direction: column;
align-items: var(--layout-justification-setting-mobile);
justify-content: start;
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile .elTypographyLink > i {
      display: none;
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile .elNavigation-menu-dropdown {
      justify-content: var(--layout-justification-setting-mobile);
display: flex;
flex-direction: column;
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile .elNavigation-menu-dropdown > a {
      align-self: var(--layout-justification-setting-mobile);
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile .elNavigation-menu-dropdown a:after {
      display: none;
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile .elNavigation-menu-dropdown > div {
      visibility: visible;
opacity: 1;
position: static;
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile .elNavigation-menu-dropdown-item {
      border-style: none;
padding-left: 10px;
padding-right: 10px;
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile .elNavigation-menu-dropdown-item:hover {
      filter: brightness(150%);
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile .elNavigation-menu-dropdown-content {
      position: static;
opacity: 1;
visibility: visible;
border-style: none;
padding-left: 10px;
padding-right: 10px;
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile .elNavigation-menu-dropdown-content a {
      display: flex;
justify-content: var(--layout-justification-setting-mobile);
    }

      [data-page-element="Navigation/V1"].elNavigator[data-overlay-menu="always"] .elNavigatorButton {
      display: flex;
    }
[data-page-element="Navigation/V1"].elNavigator[data-overlay-menu="always"] .elNavigatorDesktop {
      display: none;
    }
      
    
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-holder a {
      white-space: nowrap;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-holder {
      flex-direction: column;
margin-left: 10px;
display: flex;
background: white;
gap: 0.5em;
align-items: var(--layout-justification-setting);
    }
[data-page-element="Navigation/V1"] .elNavigatorMobile .elNavigation-menu-dropdown-holder {
      background: transparent;
box-shadow: none;
border-style: none;
    }
      [data-page-element="Navigation/V1"] .elNavigatorMobile .elNavigation-menu-dropdown-holder .elTypographyLink {
      margin-left: 0px;
border-style: none;
    }
      
    
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-item:last-child {
      border-top-width: 0px;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-item {
      position: relative;
display: inline-block;
margin-left: 0px;
cursor: pointer;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-title {
      text-decoration: none;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-title:after {
      content: '\203A';
margin-left: 4px;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-content {
      background: white;
position: absolute;
left: 100%;
top: 0px;
display: flex;
flex-direction: column;
margin-left: 0px;
visibility: hidden;
opacity: 0;
transition: opacity .2s ease;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-item:hover .elNavigation-menu-dropdown-content {
      visibility: visible;
opacity: 1;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-content a {
      margin-left: 0px;
width: 100%;
text-decoration: none;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown {
      position: relative;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown > div {
      position: absolute;
visibility: hidden;
opacity: 0;
z-index: 100;
transition: opacity .2s ease;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown:hover > div {
      visibility: visible;
opacity: 1;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown > a:after {
      content: '\203A';
margin-left: 6px;
display: inline-block;
transform: rotate(90deg);
vertical-align: middle;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown-holder > .elNavigation-menu-dropdown > a:after {
      transform: rotate(0deg);
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown .dropdown-open-left {
      right: 0px;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown .dropdown-open-left .elNavigation-menu-dropdown-content {
      right: 100%;
left: auto;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown .dropdown-open-top {
      bottom: 100%;
top: auto;
    }
[data-page-element="Navigation/V1"] .elNavigation-menu-dropdown .dropdown-open-top .elNavigation-menu-dropdown-content {
      bottom: -1px;
top: auto;
    }
[data-page-element="Navigation/V1"] .navHamburgerIcon {
      height: 20px;
width: 30px;
display: flex;
flex-direction: column;
justify-content: space-between;
    }
[data-page-element="Navigation/V1"] .navHamburgerIcon > div,
[data-page-element="Navigation/V1"] .navHamburgerIcon:before,
[data-page-element="Navigation/V1"] .navHamburgerIcon:after {
      content: "";
height: 4px;
width: 100%;
display: block;
background: rgba(0, 0, 0, 1);
border-radius: 0px;
    }
[data-page-element="Navigation/V1"] .elNavigatorWrapper .navigatorBtn {
      padding-bottom: 10px;
padding-top: 10px;
padding-left: 5px;
padding-right: 5px;
    }
[data-page-element="Navigation/V1"] .elNavigatorWrapper .navigatorBtn.twoBarHamburger > div {
      justify-content: space-evenly;
    }
[data-page-element="Navigation/V1"] .elNavigatorWrapper .navigatorBtn.twoBarHamburger .navHamburgerIcon > div {
      display: none;
    }
      @media (max-width: 770px) {
        
      [data-page-element="Navigation/V1"][data-overlay-menu="mobile"] .elNavigatorButton {
      display: flex;
    }
[data-page-element="Navigation/V1"][data-overlay-menu="mobile"] .elNavigatorDesktop {
      display: none;
    }
      
    
      }
    